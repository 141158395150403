import React from "react";
import CopyButton from "../../_molecules/copy_button/CopyButton";

export interface iCopyLineItem {
  label: string;
  eventName: string;
  value: string;
}

const CopyLineItem = ({
  label,
  eventName,
  value,
}: iCopyLineItem) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-text-sm tw-text-gray-900 tw-h-[1.85rem] tw-pb-2">
    <div className="tw-flex tw-text-left">
      <span className="tw-font-semibold sm:tw-pr-2">{label}&nbsp;</span>
      <span>{value}</span>
    </div>
    <div className="!tw-content-end">
      <CopyButton
        copyValue={value}
        label={label}
        buttonClasses="tw-border-0"
        eventName={eventName}
      >
        <span className="tw-sr-only">Copy {label}</span>
      </CopyButton>
    </div>
  </div>
);

export default CopyLineItem;
