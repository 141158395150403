import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Dropdown from "../../inputs/dropdown/dropdown";
import Typedown from "../../inputs/typedown/typedown";
import I18n from "../../../utilities/translations";
import { getUserJurisdictionCode } from "../../../utilities/user_attributes";

const EXPENSE_CATEGORY_INDEX_VALUE = 1;

const ExpenseCreationForm = ({
  jobCategoryProps,
  expenseCategoryProps,
  showJobCategoryDropdown,
  mileageCategoryIds,
  motorVehicleCategoryIds,
  nonSalaryCategoryIds,
  isMainForm,
  isHidden,
  eoyf,
}) => {
  const [expenseCategoryOptions, setExpenseCategoryOptions] = useState(expenseCategoryProps.dropdownOptions);
  const [selectedJobCategoryId, setSelectedJobCategoryId] = useState(jobCategoryProps.initialSelectedId || "");
  const [salary, setSalary] = useState(false);
  const [allowMileageExpense, setAllowMileageExpense] = useState(null); // true=mileage, false=MV, null=both mv and mileage is allowed
  const [showMileageAlert, setShowMileageAlert] = useState(false);
  const [showMotorVehicleAlert, setShowMotorVehicleAlert] = useState(false);
  const [isMileage, setIsMileage] = useState(null);
  const [isMotorVehicle, setIsMotorVehicle] = useState(null);
  const [resetTypedown, setResetTypedown] = useState(false);
  const [selectedExpenseExpenseCategoryId, setSelectedExpenseExpenseCategoryId] = useState("");
  const alertMileageText = eoyf ? I18n.t("expenses.form.mileage_eoyf_text") : I18n.t("expenses.form.mileage_text");
  const alertMileageLinkHref = I18n.t("expenses.form.mileage_link");
  const alertMotorVehicleText = I18n.t("expenses.form.motor_vehicle_text");
  const alertMotorVehicleLinkHref = I18n.t("expenses.form.mileage_link");
  const jurisdiction = getUserJurisdictionCode();

  useEffect(() => {
    updateAlert();
  }, [isMileage, isMotorVehicle, selectedJobCategoryId]);

  const handleJobCategoryChange = (state) => {
    const { selectedValue: jobCategoryId, selectedText: text, selectedOptionEl: optionElement } = state;

    const salary = Number(jobCategoryId) === -1;

    setAllowMileageExpense(optionElement.dataset.allowMileageExpense);
    updateExpenseCategoryOptions(jobCategoryId, text, salary, optionElement);
    setSalary(salary);
    setSelectedJobCategoryId(jobCategoryId);

    if (jurisdiction === "au") {
      // Custom event to send the selected job category.
      // Used in app/assets/javascripts/views/expenses.js to toggle GST on/off.
      const event = new CustomEvent("additional-expense-job-category-change", { detail: { selectedValue: jobCategoryId, salary } } );
      window.dispatchEvent(event);
    }
  };

  const updateAlert = () => {
    const showMileageAlert = (allowMileageExpense === undefined || allowMileageExpense === null) && isMileage;
    const showMotorVehicleAlert = allowMileageExpense === undefined && isMotorVehicle;

    showMileageAlert ? setShowMileageAlert(true) : setShowMileageAlert(false);
    showMotorVehicleAlert ? setShowMotorVehicleAlert(true) : setShowMotorVehicleAlert(false);
  };

  useEffect(() => {
    if (salary && nonSalaryCategoryIds.includes(selectedExpenseExpenseCategoryId)) {
      setResetTypedown(true);
    } else {
      setResetTypedown(false);
    }
  }, [selectedJobCategoryId, salary, nonSalaryCategoryIds, selectedExpenseExpenseCategoryId]);

  const onExpenseCategoryChange = (expenseCategoryId, categoryData) => {
    if (!categoryData) return;
    setSelectedExpenseExpenseCategoryId(expenseCategoryId);

    const data = categoryData[2];

    setIsMileage(data["data-is-mileage"]);
    setIsMotorVehicle(data["data-is-motor-vehicle"]);
  };

  const updateExpenseCategoryOptions = (value, text, salary, optionElement) => {
    const allowMileageExpenses = optionElement.dataset.allowMileageExpense === "true";
    const allowMotorVehicleExpenses = optionElement.dataset.allowMileageExpense === "false";
    const allowAllExpenses = optionElement.dataset.allowMileageExpense === undefined;
    let filteredOptions = null;
    const excludeCodes = (options, codes) => options.filter((option) => !codes.includes(option[EXPENSE_CATEGORY_INDEX_VALUE]));

    if (allowMileageExpenses) {
      filteredOptions = excludeCodes(expenseCategoryProps.dropdownOptions, motorVehicleCategoryIds);
    }
    if (allowMotorVehicleExpenses) {
      filteredOptions = excludeCodes(expenseCategoryProps.dropdownOptions, mileageCategoryIds);
    }
    if (allowAllExpenses) {
      filteredOptions = expenseCategoryProps.dropdownOptions;
    }
    if (salary) {
      filteredOptions = excludeCodes(expenseCategoryProps.dropdownOptions, nonSalaryCategoryIds);
    }

    setExpenseCategoryOptions([...filteredOptions]);
  };

  return (
    <>
      {jobCategoryProps && (
        <div
          className={classNames("job-categories-dropdown", {
            "expense-type-full-form": isMainForm,
            "expense-type-modal-form": !isMainForm,
            hidden: isHidden,
          })}
        >
          <Dropdown
            id={jobCategoryProps.id}
            name={`${jobCategoryProps.name}-select`}
            label={jobCategoryProps.label}
            hidden={!showJobCategoryDropdown}
            disabled={jobCategoryProps.disabled}
            optionEls={jobCategoryProps.optionEls}
            required={jobCategoryProps.required}
            tooltip={jobCategoryProps.tooltip}
            selectValue={selectedJobCategoryId}
            onChange={handleJobCategoryChange}
            hiddenInputName={jobCategoryProps.name}
          />
        </div>
      )}
      {/* Note: this is the salary value */}
      {salary && <input type="hidden" name="expense[salary]" id="salary-hidden-field" value={salary} />}

      <div
        className={classNames("md-form expense-categories required", {
          "expense-type-full-form": isMainForm,
          "expense-type-modal-form": !isMainForm,
          hidden: isHidden,
        })}
      >
        <Typedown
          dropdownOptions={expenseCategoryOptions}
          label={expenseCategoryProps.label}
          fireChangeOnLoad={expenseCategoryProps.fireChangeOnLoad}
          inputProps={{
            ...expenseCategoryProps.inputProps,
            onChange: onExpenseCategoryChange,
          }}
          componentId={isMainForm ? "" : "exp_modal_"}
          reset={resetTypedown}
        />
      </div>

      <div id="mileage-alert" className={classNames({ hidden: !showMileageAlert })}>
        <div className="mt-1 alert alert-primary" role="alert" aria-live="polite">
          <p>{alertMileageText}</p>
          <div className="mt-2">
            <a href={alertMileageLinkHref} data-category="mileage" target="_blank" rel="noreferrer">Learn more</a>
          </div>
        </div>
      </div>

      <div id="motor-vehicle-alert" className={classNames({ hidden: !showMotorVehicleAlert })}>
        <div className="mt-1 alert alert-primary" role="alert" aria-live="polite">
          <p>{alertMotorVehicleText}</p>
          <div className="mt-2">
            <a href={alertMotorVehicleLinkHref} data-category="motor-vehicle" target="_blank" rel="noreferrer">Learn more</a>
          </div>
        </div>
      </div>
    </>
  );
};

ExpenseCreationForm.defaultProps = {
  showJobCategoryDropdown: true,
  disabled: false,
};

ExpenseCreationForm.propTypes = {
  expenseCategoryProps: PropTypes.object.isRequired,
  showJobCategoryDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  mileageCategoryIds: PropTypes.array.isRequired,
  motorVehicleCategoryIds: PropTypes.array.isRequired,
  nonSalaryCategoryIds: PropTypes.array.isRequired,
  eoyf: PropTypes.bool,
};

export default ExpenseCreationForm;
