import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import {
  addYears,
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
  subYears,
} from "date-fns";

import classNames from "classnames";
import Cleave from "cleave.js/react";
import SVG from "react-inlinesvg";
import {
  useClickOutside,
  useEscapeKey,
  useCustomEventDispatch,
} from "../../utils/hooks";
import isMobile from "../../../es_utilities/isMobile";
import Icon from "../../_atoms/icons/icon/Icon";
import ClearButton from "../_elements/clear_button";
import CalendarIcon from "../../../../assets/images/icons/calendar.svg";

// Time constants for presets
const TEN_YEARS_FROM_TODAY = addYears(new Date(), 10);
const ELEVEN_YEARS_FROM_TODAY = addYears(new Date(), 11);
const TEN_YEARS_BEFORE_TODAY = subYears(new Date(), 10);
const ONE_HUNDRED_YEARS_FROM_TODAY = addYears(new Date(), 100);
const ONE_HUNDRED_YEARS_BEFORE_TODAY = subYears(new Date(), 100);

export type DatepickerInputProps = {
  value: string | Date;
  name: string;
  onChange: (value: Date) => void;
  id?: string;
  disabled?: boolean;
};
interface DatepickerProps {
  label: string;
  inputProps: DatepickerInputProps;
  latestDate?: Date;
  earliestDate?: Date;
  preset?: string;
  requiredLabel?: boolean;
  invalidText?: string;
  eventName?: string;
  legacyStyles?: boolean;
  locale?: string;
}

const Datepicker = ({
  label,
  eventName = "",
  inputProps,
  latestDate,
  earliestDate,
  preset,
  requiredLabel = false,
  invalidText = "",
  legacyStyles = true,
}: DatepickerProps) => {
  const [isDatepickerOpen, setDatepickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isMobileLabelActive, setMobileLabelActive] = useState<boolean>(false);
  const [formattedEarliestDate, setFormattedEarliestDate] =
    useState<Date | null>(null);
  const [formattedLatestDate, setFormattedLatestDate] = useState<Date | null>(
    null,
  );
  const [defaultActiveDate, setDefaultActiveDate] = useState<Date | null>(null);
  const DatepickerRef = useRef<HTMLInputElement | null>(null);
  const DatepickerInputRef = useRef<Cleave | null>(null);

  // On Mount - set the default value of the date input,
  // and assign the earliest and latest possible dates based
  // on props
  useEffect(() => {
    if (inputProps.value) {
      setSelectedDate(new Date(inputProps.value));
    } else {
      setSelectedDate(null);
    }

    if (preset === "dob") {
      setFormattedEarliestDate(ONE_HUNDRED_YEARS_BEFORE_TODAY);
      setFormattedLatestDate(new Date());
    } else if (preset === "timeless") {
      setFormattedEarliestDate(ONE_HUNDRED_YEARS_BEFORE_TODAY);
      setFormattedLatestDate(ONE_HUNDRED_YEARS_FROM_TODAY);
    } else if (preset === "expiry") {
      setFormattedEarliestDate(new Date());
      setFormattedLatestDate(ELEVEN_YEARS_FROM_TODAY);
    } else {
      if (earliestDate) {
        setFormattedEarliestDate(new Date(earliestDate));
        if (isAfter(earliestDate, new Date())) {
          setDefaultActiveDate(new Date(earliestDate));
        }
      } else {
        setFormattedEarliestDate(TEN_YEARS_BEFORE_TODAY);
      }
      if (latestDate) {
        setFormattedLatestDate(new Date(latestDate));
        if (isBefore(latestDate, new Date())) {
          setDefaultActiveDate(new Date(latestDate));
        }
      } else {
        setFormattedLatestDate(TEN_YEARS_FROM_TODAY);
      }
    }
  }, [inputProps.value, preset, earliestDate, latestDate]);

  // The labels are a bit weird for mobile date inputs
  // set its state explicitly if theres a date selected
  useEffect(() => {
    setMobileLabelActive(!!selectedDate);
  }, [selectedDate]);

  // Use the useEscapeKey hook to close the datepicker whenever the escape key is pressed
  useEscapeKey(() => {
    setDatepickerOpen(false);
  });

  useClickOutside(DatepickerRef, (event) => {
    // Since React will unmount/remount different views rather than hiding them,
    // a click on, say, a Month button in the Year view, won't be contained within
    // DatepickerRef, so this Hook will try to close the Datepicker. To fix this, we
    // check if it's parent has a React Calendar class and if it does, we can exempt it.
    if (isMobile) {
      return;
    }
    if (!event.target.parentElement) {
      setDatepickerOpen(false);
      return;
    }
    const parentElementClasses = [...event.target.parentElement.classList];
    if (
      !parentElementClasses.some((className) =>
        className.includes("react-calendar"),
      )
    ) {
      setDatepickerOpen(false);
    }

    // Reset the input value if the date is invalid
    // eslint-disable-next-line xss/no-mixed-html
    const currentInputDateString =
      DatepickerRef.current.querySelector("input").value;

    const currentInputDate = parse(
      currentInputDateString,
      "dd/MM/yyyy",
      new Date(),
    );

    if (currentInputDateString.length !== 10 || !isValid(currentInputDate)) {
      setSelectedDate(null);
    }
  });

  useEffect(() => {
    if (eventName) {
      document.addEventListener(eventName, updateSelectedDate);

      return () => {
        document.removeEventListener(eventName, updateSelectedDate);
      };
    }
  }, []);

  const setDispatchEvent = useCustomEventDispatch({
    eventName: "fieldValueChange",
    detail: {
      type: "datepicker",
      value: selectedDate,
      target: DatepickerRef.current,
    },
  });

  // Respond to a change in selectedDate.
  // - close the calendar if needed
  // - update the selectedDate
  // - fire a change event for external event listeners
  // - call the onChange inputProp if there
  const handleChange = (date, closeOnChange = true) => {
    if (selectedDate && closeOnChange) {
      setDatepickerOpen(false);
    }

    // Inform user that the form has unsaved changes.
    if (selectedDate instanceof Date) {
      const hasChanged = selectedDate === null || !(selectedDate === date);
      if (hasChanged) {
        window.unsaved_changes = true;
      }
    }
    setSelectedDate(date);
    const event = document.createEvent("Event");
    event.initEvent("change", true, true);

    DatepickerInputRef.current.element.dispatchEvent(event);

    if (inputProps.onChange) {
      inputProps.onChange(date);
    }

    setDispatchEvent(true);
  };

  // Respond the a change in the mobile Date input
  const handleMobileChange = (event) => {
    const value = event.target.valueAsDate;
    setSelectedDate(value);
    if (inputProps.onChange) {
      inputProps.onChange(value);
    }

    setDispatchEvent(true);
  };

  const handleMobileKeyDown = () => {
    setMobileLabelActive(true);
  };

  // Auto select the current date when focused and if
  // and value hasn't already been selected
  const handleFocus = () => {
    setDatepickerOpen(true);
  };

  // Respond to the clear button being pressed
  // - reset the input
  // - fire the appropriate events
  const handleClear = () => {
    setSelectedDate(null);
    setDatepickerOpen(false);
    const event = document.createEvent("Event");
    event.initEvent("change", true, true);

    DatepickerInputRef.current.element.dispatchEvent(event);
    if (inputProps.onChange) {
      inputProps.onChange(null);
    }

    setDispatchEvent(true);
  };

  // Respond to a user manually typing a date
  // - Allows the user to type in a string. Only accepts valid chars
  // - Parses the string as a Date when it reaches a full Date length (10 chars)
  // - Checks if the new Date is within the specified bounds for min and max Date
  // - Resets the input if the typed date is invalid
  const handleTyping = (event) => {
    const { value } = event.target;
    let typedDate;
    if (value.length !== 10) {
      typedDate = value;
    } else {
      try {
        typedDate = parse(value, "dd/MM/yyyy", new Date());
        if (
          (typedDate === formattedLatestDate ||
            isBefore(typedDate, formattedLatestDate)) &&
          (typedDate === formattedEarliestDate ||
            isAfter(typedDate, formattedEarliestDate))
        ) {
          handleChange(typedDate, false);
        } else {
          typedDate = "";
        }
      } catch (error) {
        typedDate = value;
      }
    }
    setSelectedDate(typedDate);
    setDispatchEvent(true);
  };

  // Prevent a form submit when Enter is pressed
  const handleEnter = (event) => {
    const { key } = event;
    if (key === "Enter") {
      event.preventDefault();
      setDatepickerOpen(false);
      DatepickerInputRef.current.element.blur();
    }
  };

  // Destructure out the onChange prop since we don't need to send that to the mobile date input
  const otherInputProps = { ...inputProps };
  delete otherInputProps.onChange;

  // Changes the selected date if a date is passed by a custom event
  const updateSelectedDate = (e) => {
    if (e.detail.expenseDate) {
      const { expenseDate } = e.detail;
      setSelectedDate(new Date(expenseDate));
      setDispatchEvent(true);
    }
  };

  const mobileComponent = legacyStyles ? (
    <>
      <input
        type="date"
        {...otherInputProps}
        id={otherInputProps.id || otherInputProps.name}
        className={classNames("datepicker-native form-control", {
          required: requiredLabel,
          invalid: invalidText.length,
        })}
        onChange={handleMobileChange}
        onKeyDown={handleMobileKeyDown}
        min={
          formattedEarliestDate instanceof Date
            ? format(formattedEarliestDate, "yyyy-MM-dd")
            : null
        }
        max={
          formattedLatestDate instanceof Date
            ? format(formattedLatestDate, "yyyy-MM-dd")
            : null
        }
        value={
          selectedDate instanceof Date ? format(selectedDate, "yyyy-MM-dd") : ""
        }
      />
      <label
        className={classNames("hnry-label", {
          active: isMobileLabelActive,
        })}
        htmlFor={otherInputProps.id || otherInputProps.name}
      >
        {label}
      </label>
    </>
  ) : (
    <>
      <label
        className={classNames("hnry-label", {
          active: isMobileLabelActive,
          "hnry-label--required": requiredLabel,
        })}
        htmlFor={otherInputProps.id || otherInputProps.name}
      >
        {label}
      </label>
      <input
        type="date"
        {...otherInputProps}
        id={otherInputProps.id || otherInputProps.name}
        className={classNames("hnry-input no-bs", {
          "hnry-input--invalid": invalidText.length,
        })}
        onChange={handleMobileChange}
        onKeyDown={handleMobileKeyDown}
        min={
          formattedEarliestDate instanceof Date
            ? format(formattedEarliestDate, "yyyy-MM-dd")
            : null
        }
        max={
          formattedLatestDate instanceof Date
            ? format(formattedLatestDate, "yyyy-MM-dd")
            : null
        }
        value={
          selectedDate instanceof Date ? format(selectedDate, "yyyy-MM-dd") : ""
        }
      />
    </>
  );

  const desktopComponent = legacyStyles ? (
    <>
      <Cleave
        options={{
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        }}
        type="text"
        ref={DatepickerInputRef}
        onFocus={() => handleFocus()}
        autoComplete="off"
        className={classNames("form-control", {
          "datepicker-dob-input": preset === "dob",
          invalid: invalidText.length,
        })}
        {...inputProps}
        id={inputProps.id || inputProps.name}
        onKeyDown={(event) => handleEnter(event)}
        onChange={(event) => handleTyping(event)}
        value={
          selectedDate instanceof Date ? format(selectedDate, "dd/MM/yyyy") : ""
        }
      />
      <label htmlFor={otherInputProps.id || otherInputProps.name}>
        {label}
      </label>
      <ClearButton
        isVisible={!!selectedDate && !otherInputProps.disabled}
        onClick={() => handleClear()}
      />
      {preset !== "dob" && (
        <SVG src={CalendarIcon} className="icon" aria-hidden="true" />
      )}
    </>
  ) : (
    <>
      <label
        htmlFor={otherInputProps.id || otherInputProps.name}
        className="hnry-label"
      >
        {label}
      </label>
      <div className="tw-relative">
        <Cleave
          options={{
            date: true,
            delimiter: "/",
            datePattern: ["d", "m", "Y"],
          }}
          type="text"
          ref={DatepickerInputRef}
          onFocus={() => handleFocus()}
          autoComplete="off"
          className={classNames("hnry-input no-bs", {
            "datepicker-dob-input": preset === "dob",
            "hnry-input--invalid": invalidText.length,
          })}
          {...inputProps}
          id={inputProps.id || inputProps.name}
          onKeyDown={(event) => handleEnter(event)}
          onChange={(event) => handleTyping(event)}
          value={
            selectedDate instanceof Date
              ? format(selectedDate, "dd/MM/yyyy")
              : ""
          }
        />
        <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
          {!!selectedDate && !otherInputProps.disabled && (
            <button type="button" onClick={handleClear} className="tw-p-1">
              <Icon type="XCircleIcon" />

              <span className="tw-sr-only">Clear date</span>
            </button>
          )}
          {preset !== "dob" && (
            <button
              type="button"
              onClick={() => setDatepickerOpen(!isDatepickerOpen)}
              className="tw-p-1"
            >
              <Icon type="CalendarDaysIcon" />

              <span className="tw-sr-only">
                {isDatepickerOpen ? "Close" : "Open"} date picker
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div>
      {isMobile ? (
        mobileComponent
      ) : (
        <div
          className={`datepicker-input${requiredLabel ? " required" : ""}`}
          ref={DatepickerRef}
        >
          {desktopComponent}

          {preset !== "dob" && isDatepickerOpen && (
            <Calendar
              defaultActiveStartDate={defaultActiveDate || null}
              maxDate={formattedLatestDate}
              minDate={formattedEarliestDate}
              value={selectedDate instanceof Date ? selectedDate : undefined}
              maxDetail="month"
              minDetail="year"
              onChange={(newDate) => handleChange(newDate)}
            />
          )}
        </div>
      )}
      {invalidText.length > 0 && (
        <p className="tw-mt-2 tw-text-sm tw-text-red-600 tw-block">
          {invalidText}
        </p>
      )}
    </div>
  );
};

export default Datepicker;
