import React from "react";
import Icon from "../../_atoms/icons/icon/Icon";
import { iLineItem } from "./types";

interface iDeleteItem {
  item: iLineItem; // the LineItem or QuoteItem object that is being removed from the Invoice or Quote
  onClick: (item: object) => void;
}

const DeleteItem = ({item, onClick}: iDeleteItem) =>
  <div className="col-1 pr-0 d-flex align-items-center">
    <button 
      onClick={() => onClick(item)}
      className="hnry-button hnry-button--link"
    >
      <span className="tw-sr-only">Delete line item</span>
      <Icon type="TrashIcon" classes="tw-text-red-500 !tw-bg-red-100 hover:!tw-bg-red-200" />
    </button>
  </div>

export default DeleteItem;
