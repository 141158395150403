import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Referral from "../../referral_friends/index";

import NavigationSidebarPanel from "../navigation_sidebar_panel/NavigationSidebarPanel";
import { iSidebarProps } from "../navigation_sidebar_panel/types";

import Icon from "../../_atoms/icons/icon/Icon";

export default function NavigationSidebar({
  currentUser,
  navItems,
  platform,
  raf,
  settingItems,
  settingsPath,
  signOutPath,
}: iSidebarProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closeSidepanel = () => setIsOpen(false);

  const sidebarProps = {
    closeSidepanel,
    navItems,
    platform,
    settingItems,
    settingsPath,
    signOutPath,
    currentUser,
  };

  const openNavSidebarHandler = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    document.addEventListener("hnry:openNavSidebar", openNavSidebarHandler);
    return () => {
      document.removeEventListener(
        "hnry:openNavSidebar",
        openNavSidebarHandler,
      );
    };
  }, []);

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <div
          className={`tw-relative tw-z-20 lg:tw-hidden${
            isOpen ? "" : " tw-pointer-events-none"
          }`}
          role="dialog"
          aria-modal="true"
          aria-label="Site navigation"
        >
          <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-gray-900/80 data-[state=open]:tw-animate-opacityShow data-[state=closed]:tw-animate-opacityHide" />
          <div className="tw-fixed tw-inset-0 tw-flex">
            <Dialog.Content
              className="data-[state=open]:tw-animate-slideInFromLeft data-[state=closed]:tw-animate-slideOutToLeft tw-relative tw-mr-16 tw-flex tw-w-full tw-max-w-xs tw-flex-1"
              onClick={(e) => {
                if (e.target instanceof HTMLAnchorElement && e.target.href) {
                  setIsOpen(false);
                }
              }}
            >
              <Dialog.Close
                className="tw-absolute tw-left-full tw-top-0 tw-flex tw-w-16 tw-justify-center tw-pt-2.5 data-[state=open]:tw-animate-opacityShow data-[state=closed]:tw-animate-opacityHide"
                data-state={isOpen ? "open" : "closed"}
                onClick={() => setIsOpen(false)}
              >
                <span className="tw-sr-only">Close sidebar</span>
                <Icon type="XMarkIcon" size="lg" classes="tw-text-white" />
              </Dialog.Close>
              <Dialog.Title />
              <Dialog.Description />
              <NavigationSidebarPanel {...sidebarProps} />
            </Dialog.Content>
          </div>
        </div>
      </Dialog.Root>

      <div className="tw-hidden lg:tw-fixed lg:tw-inset-y-0 lg:tw-z-10 lg:tw-flex hui-layout--content-width lg:tw-flex-col">
        <NavigationSidebarPanel {...sidebarProps} />
      </div>
      <Referral
        referralUrl={raf.referralUrl}
        creditBalance={raf.creditBalance}
        pendingTotal={raf.pendingTotal}
        promoCode={raf.promoCode}
        launcherType="headless"
        buttonText="Refer a friend"
        buttonEventName="free_credit_btn_clicked"
        promoCodeUrl={raf.promoCodeUrl}
      />
    </>
  );
}
