import React from "react";
import { useInvoiceQuoteContext } from "../InvoiceQuoteContext";

const ClientField = ({ client, label }) => {
  const { currencyOptions } = useInvoiceQuoteContext();

  return currencyOptions ? (
    <div className="md-form !tw-m-0">
      <input
        type="text"
        id="client_name"
        value={client}
        disabled
        className="form-control"
      />
      <label id="task-label" className="active" htmlFor="client_name">
        {label}
      </label>
      <input
        type="text"
        id="currency_code"
        value={currencyOptions.code}
        disabled
        className="hidden"
      />
    </div>
  ) : null;
};

export default ClientField;
