import React, { useState } from "react";
import Select from "../../_atoms/select/Select";
import Tooltip from "../../tooltip";
import { ordinalize } from "../../utils/general_helpers";
import I18n from "../../../utilities/translations";

export type RecurrencePeriod =
  | "annually"
  | "fortnightly"
  | "monthly"
  | "never"
  | "weekly";

export type RecurrenceConfig = {
  recurrenceDay: number;
  recurrenceMaxOccurrences: number;
  recurrencePeriod: RecurrencePeriod;
  recursIndefinitely?: boolean;
};

export interface iRecurrenceFields {
  recurrenceConfig: RecurrenceConfig;
  setRecurrenceConfig: (value: RecurrenceConfig) => void;
}

const ctx = { scope: "invoices.recurrence" };

const RecurrencePeriod = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const [selected, setSelected] = useState(recurrenceConfig?.recurrencePeriod || "never");
  const periodOptions = [
    { name: "Never", value: "never" },
    { name: "Weekly", value: "weekly" },
    { name: "Fortnightly", value: "fortnightly" },
    { name: "Monthly", value: "monthly" },
    { name: "Yearly", value: "annually" },
  ];

  const handleRecurrencePeriodChange = (value) => {
    setRecurrenceConfig({
      recurrencePeriod: value,
      recurrenceDay: 1,
      recursIndefinitely: false,
      recurrenceMaxOccurrences: 1,
    });

    setSelected(value);
  };

  return (
    <div className="tw-inline-flex tw-mr-2 tw-mb-2">
      <Select
        options={periodOptions}
        label="How frequently to repeat sending this invoice"
        hideLabel={true}
        name="invoice[invoice_config_attributes][recurrence_period]"
        id="invoice[invoice_config_attributes][recurrence_period]"
        selectedValue={selected}
        onChange={(value) => handleRecurrencePeriodChange(value)}
      />
    </div>
  );
};

const RecurrenceDay = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const [selected, setSelected] = useState(recurrenceConfig?.recurrenceDay?.toString() || "1");

  const recurrenceDayOptions = () => {
    let options = [];

    if (recurrenceConfig.recurrencePeriod === "monthly") {
      const monthlyDayOptions = new Array(28).fill(0).map((_, index) => {
        const step = index + 1;
        return {
          name: ordinalize(step),
          value: String(step),
        };
      });
      options = [...monthlyDayOptions];
    } else if (recurrenceConfig.recurrencePeriod === "annually") {
      options = [];
    } else {
      // weekly or fortnightly
      options = [
        { name: "Mondays", value: "1" },
        { name: "Tuesdays", value: "2" },
        { name: "Wednesdays", value: "3" },
        { name: "Thursdays", value: "4" },
        { name: "Fridays", value: "5" },
        { name: "Saturdays", value: "6" },
        { name: "Sundays", value: "0" },
      ];
    }

    return options;
  };

  const handleRecurrenceDayChange = (value) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recurrenceDay: value,
    });

    setSelected(value);
  };

  return (
    <div className="tw-inline-flex tw-items-center tw-mr-2 tw-mb-2">
      <span
        className="tw-mr-2 tw-mb-0 tw-text-base"
      >
        {recurrenceConfig.recurrencePeriod === "monthly" ? "on the" : "on"}
      </span>
      <Select
        options={recurrenceDayOptions()}
        label="The day on which to send repeated invoices"
        hideLabel={true}
        name="invoice[invoice_config_attributes][recurrence_day]"
        id="invoice[invoice_config_attributes][recurrence_day]"
        selectedValue={selected}
        onChange={(value) => handleRecurrenceDayChange(value)}
      />
    </div>
  );
};

const IndefiniteChoice = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const choiceOptions = [
    { name: "Never", value: "never" },
    { name: "After", value: "after" },
  ];

  const handleIndefiniteChoiceChange = (value) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recursIndefinitely: value === "never",
    });
  };

  return (
    <div className="tw-inline-flex tw-items-center tw-mr-2 tw-mb-2">
      <span
        className="tw-mr-2 tw-mb-0 tw-text-base"
      >
        ending
      </span>
      <Select
        options={choiceOptions}
        label="Whether the repeating invoice sequence should end"
        hideLabel={true}
        name="ui[recurrence_ending]"
        id="ui[recurrence_ending]"
        selectedValue={recurrenceConfig.recursIndefinitely ? "never" : "after"}
        onChange={(value) => handleIndefiniteChoiceChange(value)}
      />
    </div>
  );
};

const RecurrenceOccurrence = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const [selected, setSelected] = useState(recurrenceConfig?.recurrenceMaxOccurrences?.toString() || "1");
  const occurrenceCountOptions = new Array(104).fill(0).map((_, index) => {
    const step = String(index + 1);
    return {
      name: step,
      value: step,
    };
  });

  const handleRecurrenceOccurrenceChange = (value) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recurrenceMaxOccurrences: value,
    });

    setSelected(value);
  };

  return (
    <div className="tw-inline-flex tw-items-center tw-mb-2 tw-mr-2">
      <Select
        options={occurrenceCountOptions}
        label="The number of times this invoice should be sent again"
        hideLabel={true}
        name="invoice[invoice_config_attributes][recurrence_max_occurrences]"
        id="invoice[invoice_config_attributes][recurrence_max_occurrences]"
        selectedValue={selected}
        onChange={(value) => handleRecurrenceOccurrenceChange(value)}
      />
      <span className="tw-ml-2 tw-mb-0 tw-text-base">
        occurrence{selected !== "1" ? "s" : ""}
      </span>
    </div>
  );
};

const RecurrenceFields = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const isRecurrence = recurrenceConfig?.recurrencePeriod && recurrenceConfig?.recurrencePeriod !== "never";
  const showRecurrenceDay = ["weekly", "fortnightly", "monthly"].includes(recurrenceConfig?.recurrencePeriod);
  const showRecurrenceOccurrence = recurrenceConfig?.recursIndefinitely === false;

  return recurrenceConfig ? (
    <>
      <span className="hnry-label">Invoice repeats<Tooltip otherClasses="tw-ml-1.5" text={I18n.t("tooltip", ctx)} /></span>
      <div className="sm:tw-col-span-2 tw-flex tw-flex-wrap tw-items-center" data-testid="recurrence-fields">
        <RecurrencePeriod {...{ recurrenceConfig, setRecurrenceConfig }} />
        {isRecurrence && (
          <>
            {showRecurrenceDay && (
              <RecurrenceDay {...{ recurrenceConfig, setRecurrenceConfig }} />
            )}
            <IndefiniteChoice {...{ recurrenceConfig, setRecurrenceConfig }} />
            {showRecurrenceOccurrence && (
              <RecurrenceOccurrence {...{ recurrenceConfig, setRecurrenceConfig }} />
            )}
          </>
        )}
      </div>
    </>
  ) : null;
};

export default RecurrenceFields;
