import React from "react";
import InvoiceStatus, { iInvoiceStatus } from "../invoices/InvoiceStatus";

type InvoiceType = {
  id: number;
  invoiceNumber: string;
  daysTillDueInWords: string;
  clientName: string;
  total: number;
  statusObject: iInvoiceStatus,
  accessToken: string;
  daysTillDue: number;
};

interface iOutstandingInvoices {
  invoices: InvoiceType[];
}

const OutstandingInvoices = ({
  invoices,
}: iOutstandingInvoices) => (
  <ol className="hui-stacked-list tw-overflow-y-auto tw-max-h-72 md:tw-max-h-96 xl:tw-max-h-80">
    {invoices
      .sort((a, b) => (a.daysTillDue < b.daysTillDue ? -1 : 1))
      .map((inv) => {
        const {
          id,
          total,
          invoiceNumber,
          daysTillDueInWords,
          clientName,
          statusObject,
          accessToken,
        } = inv;

        return (
          <li key={`pay-line-item-${id}`}>
            <a
              href={accessToken}
              data-track-click='{ "eventName": "dashboard_outstanding_invoices_item_click" }'
              className="hui-stacked-list__item"
            >
              <div className="hui-stacked-list__item-left">
                <p className="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-900 tw-mb-0">
                  <span className="tw-text-ellipsis tw-overflow-hidden">{clientName}</span>
                </p>
                <p className="tw-truncate tw-text-gray-500 tw-mb-0">
                  <span className="tw-text-xs tw-leading-4">{`Due ${daysTillDueInWords} • ${invoiceNumber}`}</span>
                </p>
              </div>
              <div className="hui-stacked-list__item-right tw-flex-col">
                <p className="tw-text-base xl:tw-text-sm 2xl:tw-text-base tw-leading-6 tw-text-gray-900 tw-mb-0 tw-mr-2 tw-font-semibold">{total}</p>
                <InvoiceStatus {...statusObject} />
              </div>
            </a>
          </li>
        );
      })
    }
  </ol>
);

export default OutstandingInvoices;
