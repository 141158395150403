import React, { useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import Icon from "../../_atoms/icons/icon/Icon";
import Button from "../../_atoms/button/Button";
import "./styles.scss";

export interface iModal {
  cancelCTA?: string;
  children: React.ReactNode;
  closable?: boolean;
  confirmCTA?: string;
  disabled?: boolean;
  double?: boolean;
  icon?: string;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showIcon?: boolean;
  title: string;
  variant?: "danger" | "primary";
  id?: string;
  onOpenAutoFocus?: (event: Event) => void;
  hideOverlay?: boolean;
  extraHeaderContent?: React.ReactNode;
}

const Modal = ({
  cancelCTA = "Cancel",
  children,
  closable = false,
  confirmCTA = "Confirm",
  disabled = false,
  double = false,
  icon = "BoltIcon",
  loading = false,
  onCancel = undefined,
  onConfirm,
  open = false,
  setOpen,
  showIcon = false,
  title,
  variant = "primary",
  id = undefined,
  onOpenAutoFocus = undefined,
  hideOverlay = false,
  extraHeaderContent,
}: iModal) => {
  const cancelButtonRef = useRef(null);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Overlay
        className={classNames({
          "hnry-dialog-transition data-[state=open]:tw-animate-opacityShowFast data-[state=closed]:tw-animate-opacityHideFast":
            !hideOverlay,
        })}
      />
      <Dialog.Content
        onOpenAutoFocus={onOpenAutoFocus}
        id={id}
        className={classNames(
          "hnry-dialog-panel",
          {
            "hnry-dialog-panel--double": double,
          },
          "data-[state=open]:tw-animate-opacityShowFast data-[state=closed]:tw-animate-opacityHideFast",
          {
            "!tw-shadow-[0_5px_25px_0_rgb(0,0,0,0.4)]": hideOverlay, // A stronger shadow is needed for the overlay to be visible
          },
        )}
      >
        {showIcon ? (
          <div
            className={`hnry-dialog-panel-icon hnry-dialog-panel-icon--${variant}`}
          >
            <Icon type={icon} />
          </div>
        ) : null}
        <div className="hnry-dialog-panel-content">
          <div className="hnry-dialog-panel-header tw-gap-4">
            {extraHeaderContent}

            <Dialog.Title className="hnry-dialog-panel-header__title">
              {title}
            </Dialog.Title>
            {closable && (
              <Dialog.Close asChild>
                <button
                  className="hnry-button hnry-button--link !tw-w-auto"
                  onClick={handleCancel}
                >
                  <Icon type="XMarkIcon" hoverOn classes="!tw-mx-0" />
                  <span className="tw-sr-only">Dismiss</span>
                </button>
              </Dialog.Close>
            )}
          </div>
          {children}
          {(onConfirm || !closable) && (
            <div
              className={classNames("hnry-dialog-panel-actions", {
                closable,
              })}
            >
              {onConfirm && (
                <Button
                  type="button"
                  onClick={onConfirm}
                  loading={loading}
                  variant={variant}
                  disabled={disabled}
                >
                  {confirmCTA}
                </Button>
              )}
              {!closable && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleCancel}
                  ref={cancelButtonRef}
                >
                  {cancelCTA}
                </Button>
              )}
            </div>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default Modal;
