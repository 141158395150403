import React from "react";
import Icon from "../_atoms/icons/icon/Icon";
import emptyStateImg from "../../../assets/images/empty_states/module_expenses.svg";
import I18n from "../../utilities/translations";

interface iExpensesEmptyState {
  title: string;
  subtext: string;
  addNewDisabled?: boolean;
}

const ExpensesEmptyState = ({ title, subtext, addNewDisabled }: iExpensesEmptyState) => (
  <div className="tw-text-center">
    <div className="hui-card-empty">
      <img
        src={emptyStateImg}
        alt="Expenses with background"
        width="140"
        height="120"
      />
      <h3 className="hui-card-empty__title">{title}</h3>
      <p className="hui-card-empty__subtext">{subtext}</p>
      {!addNewDisabled && (<a
        className="hnry-button hnry-button--primary"
        href="/expenses/expense_creation/expense_type"
        data-remote="true"
        data-track-click={JSON.stringify({
          eventName: "dashboard_expenses_empty_state_new_click",
        })}
      >
        <Icon classes="-tw-ml-2" type="PlusIcon" />
        {I18n.t("home.index.cards.empty_state.expenses.new_expense")}
      </a>) }
    </div>
  </div>
);

export default ExpensesEmptyState;
