// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect, CSSProperties } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import NumberInput from "cleave.js/react"; // Just renaming the default export from Cleave, we should have our own number input ideally
import isEqual from "lodash/isEqual";
import { round2Dp } from "../../utils/base_helper";
import { iLineItem } from "./types";
import I18n from "../../../utilities/translations";
import Icon from "../../icon/Icon";
import Typedown from "../../inputs/typedown/typedown";
import Ellipses from "../../ellipses/ellipses";
import CurrencyInput from "../../inputs/currency/CurrencyInput";
import { useInvoiceQuoteContext } from "../../invoice_quote/InvoiceQuoteContext";
import InlineControls from "./InlineControls";
import isMobile from "../../../es_utilities/isMobile";

// Represents the UI for a single LineItem
const LineItem = ({
  item,
  handleRemoveItem,
  lineItemsAttributesName,
  descColClasses,
  hideGstOnMobile,
}: iLineItem) => {
  const id = item.sortID;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    background: "white",
    boxShadow: isDragging ? "0 0 10px 5px rgba(0, 0, 0, 0.05)" : "",
    position: isDragging ? "relative" : "inherit",
    zIndex: isDragging ? 1000 : 0,
  };

  // Form value state
  const [serviceName, setServiceName] = useState(item.updated_name || "");
  const [quantity, setQuantity] = useState(item.quantity);
  const [itemIncludesSalesTax, setItemIncludesSalesTax] = useState(
    item.includes_sales_tax,
  );
  // Since the price of a line item is saved as either `service_price` or `price` for
  // Invoices and Quotes, respectively, to set the initial state we need to first need to check
  // which one exists and then set it to that value. If they are both null (i.e: a blank line)
  // then initialise the price as an empty string
  const [price, setPrice] = useState(() => {
    if (
      !quantity &&
      item.service_price &&
      item.service_price.length &&
      item.price &&
      item.price.length
    ) {
      return "";
    }

    if (Number.isFinite(item.service_price)) {
      return item.service_price;
    }
    if (Number.isFinite(item.price)) {
      return item.price;
    }
    return "";
  });

  const handleIncludesSalesTaxChange = (value) => {
    const { modelId, model } = item;

    setItemIncludesSalesTax(value);
    window.analytics.track(`${model}_line_item_gst_toggled`, {
      feature_code: "GST on Services",
      includes_gst: value,
      invoice_id: modelId || `new_draft_${model}`,
      line_item_id: id || "new_line_item",
    });
  };

  // Get the relevant values from context
  const {
    updateListItem,
    salesTaxRate,
    formNameFor,
    allowSalesTaxExemptItems,
    services,
  } = useInvoiceQuoteContext();

  // Every time any of the inputs on the LineItem change (i.e: the description, quantity, price, or GST-expemptness),
  // Make sure the values are formatted correctly, and publish the changes to the version of the item that lives in context
  useEffect(() => {
    // Make sure numbers are parsed and rounded appropriately.
    // If value exists, make parse it as a number so it can be rounded. If it fails parsing and turns into `NaN`, fallback to 0
    // If a value doesn't exist, just save it as an empty string
    const priceAsNumber = price ? round2Dp(parseFloat(price) || 0) : "";
    const quantityAsNumber = quantity
      ? round2Dp(parseFloat(quantity) || 0)
      : "";
    const salesTax = itemIncludesSalesTax
      ? round2Dp(priceAsNumber * salesTaxRate * quantityAsNumber) || 0
      : 0;

    // Contstruct a new Item with all the correct parsed and formatted values
    const newItem = {
      ...item,
      updated_name: serviceName,
      service_price: priceAsNumber,
      quantity: quantityAsNumber,
      total: round2Dp(priceAsNumber * quantityAsNumber),
      sales_tax: salesTax,
    };

    // Perform a deep comparison to make sure that somehow the newItem and existing one are not the same
    // then update the item in context if they are at all different
    if (!isEqual(newItem, item)) {
      updateListItem(newItem);
    }
  }, [serviceName, quantity, price, itemIncludesSalesTax]);

  // Handles setting the internal quantity state value
  // It strips out the "," from number values
  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value.replace(/\\,/g, ""));
  };

  // Handles setting the internal price state value
  // It strips out the "," from number values
  const handlePriceChange = ({ target }) => {
    setPrice(target.value.replace(/\\,/g, ""));
  };

  // Handles setting the internal serviceName value, as well as the price if required
  // This gets fired when a dropdown option from Typedown is selected, NOT whenever
  // the input is typed in (see onType handler for Typedown for this). Typedown will
  // return a serviceId, which we lookup in the `services` value in context to get the name
  // and default price. We then update the price and serviceName which these values
  const handleServiceNameChange = () => {
    const selectedItem = services.find((service) => service[1] === id);
    if (selectedItem) {
      const servicePrice = selectedItem[selectedItem.length - 1]["data-price"];
      const selectedServiceName = selectedItem[0];

      setPrice(servicePrice);
      setServiceName(selectedServiceName);
    }
  };

  const handleDiscountChange = () => {
    const currentPrice = item.price || item.service_price;

    if (currentPrice !== "" && !Number.isNaN(currentPrice)) {
      const applied = parseFloat(currentPrice) * -1.0;

      setPrice(applied.toFixed(2));
    }
  };

  // Shared input config for all inputs for the LineItem, and
  // the name for sales tax in the current jurisdiction
  const nestedFormDetails = {
    nestedAtributeFor: lineItemsAttributesName,
    index: item.order - 1,
  };
  const salesTaxName = I18n.t("sales_tax.short_name");

  // These are the actions that are available for the Ellipses component (shown on smaller devices)
  // The text value for toggling sales tax will change between "remove" and "add" based on whether the item includes sales tax or not,
  // but we only show this option to the user if they are allowed to toggle this setting
  const ellipsesActions = [
    {
      text: itemIncludesSalesTax
        ? `Remove ${salesTaxName}`
        : `Add ${salesTaxName}`,
      onClick: () => setItemIncludesSalesTax(!itemIncludesSalesTax),
      actionName: "gst",
    },
    {
      text:
        price === "" || Number(price) >= 0
          ? "Apply as discount"
          : "Remove discount",
      onClick: () => handleDiscountChange(),
      actionName: "discount",
    },
    {
      text: "Delete",
      onClick: () => handleRemoveItem(item),
      actionName: "delete",
    },
  ].filter(({ actionName }) => {
    if (
      actionName === "delete" ||
      (allowSalesTaxExemptItems && actionName === "gst") ||
      (isMobile && actionName === "discount")
    ) {
      return true;
    }

    return false;
  });

  return (
    <div
      key={`line-item-${id}`}
      className="d-flex pt-1 align-items-center container px-0 invoice-line-item-row"
      style={style}
      ref={setNodeRef}
    >
      <div className="m-0 row container p-0 w-100">
        <div
          className={`${descColClasses} d-flex md-form align-items-start m-0 pl-0`}
        >
          <div {...attributes} {...listeners}>
            <Icon
              type="actions/order"
              label="Reorder this line item"
              className="mt-05"
            />
          </div>
          <div className="pl-3 w-100">
            <Typedown
              dropdownOptions={services}
              label="Invoice item name"
              labelIsHidden
              iconIsHidden
              fireChangeOnLoad={false}
              allowFromOutsideOptions
              useInputValueForSubmission
              asTextarea
              inputProps={{
                name: formNameFor("updated_name", nestedFormDetails),
                type: "text",
                value:
                  services.find((service) => service[0] === serviceName) ||
                  serviceName,
                onChange: () => handleServiceNameChange(),
                onType: (event) => setServiceName(event.target.value),
              }}
            />
          </div>
        </div>
        <div className="col-1 px-0 invoice-quantity-col d-flex align-items-end">
          <NumberInput
            id={formNameFor("quantity", nestedFormDetails)}
            name={formNameFor("quantity", nestedFormDetails)}
            className="form-control text-right mb-0"
            value={quantity}
            onChange={handleQuantityChange}
            inputMode="decimal"
            options={{
              numeral: true,
            }}
            autoComplete="off"
          />
          <label
            htmlFor={formNameFor("quantity", nestedFormDetails)}
            className="visually-hidden"
          >
            Quantity
          </label>
        </div>
        <div className="col-2 pr-0 invoice-price-col d-flex align-items-end">
          <CurrencyInput
            inputProps={{
              name: formNameFor("service_price", nestedFormDetails),
              value: price,
              onChange: handlePriceChange,
              className: "text-right mb-0",
              inputMode: "decimal",
              autoComplete: "off",
            }}
            label="Price"
            labelIsHidden
            blankDefault
          />
        </div>
        {allowSalesTaxExemptItems || isMobile ? (
          <Ellipses
            actions={ellipsesActions}
            classes="col-1 tw-pt-2 invoice-actions-col"
          >
            <InlineControls
              {...{
                item,
                nestedFormDetails,
                itemIncludesSalesTax,
                handleRemoveItem,
                formNameFor,
                allowSalesTaxExemptItems,
                hideGstOnMobile,
                handleIncludesSalesTaxChange,
              }}
            />
          </Ellipses>
        ) : (
          <InlineControls
            {...{
              item,
              nestedFormDetails,
              itemIncludesSalesTax,
              setItemIncludesSalesTax,
              handleRemoveItem,
              formNameFor,
              hideGstOnMobile,
            }}
          />
        )}
        <input
          type="hidden"
          name={formNameFor("quantity", nestedFormDetails)}
          value={item.quantity}
        />
        {item.id && (
          <input
            type="hidden"
            name={formNameFor("id", nestedFormDetails)}
            value={item.id}
          />
        )}
        <input
          type="hidden"
          name={formNameFor("service_id", nestedFormDetails)}
          value={item.service_id}
        />
        <input
          type="hidden"
          name={formNameFor("service_category", nestedFormDetails)}
          value={item.category}
        />
        <input
          type="hidden"
          name={formNameFor("sales_tax", nestedFormDetails)}
          value={item.sales_tax}
        />
        <input
          type="hidden"
          name={formNameFor("order", nestedFormDetails)}
          value={item.order}
        />
      </div>
    </div>
  );
};

export default LineItem;
