import React from "react";
import { useInvoiceQuoteContext } from "../../invoice_quote/InvoiceQuoteContext";
import Toggle from "../../inputs/toggle/Toggle";
import CurrencyInput from "../../inputs/currency/CurrencyInput";
import { iInlineControls } from "./types"
import DeleteItem from "./DeleteItem";

// This component handles the controls that live next to the inputs of a lineItem
// In all cases, there will be a icon button to delete the item. But if the user has
// specified that they need to, they have an option to toggle sales tax on or off for
// the given lineItem.
// The state and handlers for these controls are specified in the LineItemContent component
const InlineControls = ({
  allowSalesTaxExemptItems,
  formNameFor,
  handleRemoveItem,
  item,
  itemIncludesSalesTax,
  handleIncludesSalesTaxChange,
  hideGstOnMobile,
  nestedFormDetails,
}: iInlineControls) => {
  const { includesSalesTax } = useInvoiceQuoteContext();

  if (includesSalesTax) {
    return (
      <>
        {allowSalesTaxExemptItems && (
          <div className="col-1">
            <Toggle
              inputProps={{
                value: Number(itemIncludesSalesTax),
                name: formNameFor("includes_sales_tax", nestedFormDetails),
                onChange: () => handleIncludesSalesTaxChange(!itemIncludesSalesTax),
              }}
              label="Includes GST"
              labelIsHidden
              className="mt-1"
            />
          </div>
        )}
        {(!allowSalesTaxExemptItems && !hideGstOnMobile) && (
          <div className="col-2 pr-0 d-flex align-items-end">
            <CurrencyInput
              inputProps={{
                name: formNameFor("sales_tax", nestedFormDetails),
                value: item.salesTax,
                className: "text-right mb-0",
                inputMode: "decimal",
                readOnly: true,
              }}
              label="sales_tax"
              labelIsHidden
              blankDefault
            />
          </div>
        )}
        <DeleteItem 
          onClick={handleRemoveItem}
          item={item}
        />
      </>
    );
  }

  return <DeleteItem 
    onClick={handleRemoveItem}
    item={item}
  />;
};

export default InlineControls;
