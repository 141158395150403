import React, { useMemo } from "react";
import { UserAccountDetails } from "../../types/user.type";
import AccountDetailsShareModule from "./AccountDetailsShareModule";
import AccountDetailsCopyLineItem from "./account_details/CopyLineItem";
import I18n from "../../utilities/translations";

interface iAccountDetailsAuModule {
  currentUser: UserAccountDetails;
}

const ctx = { scope: "users.financial.account_details" };

const AccountDetailsModule = ({ currentUser }: iAccountDetailsAuModule) => {
  const { accountName, accountNumber, bsb, bankName, swiftCode } = currentUser;

  const domesticDetails = useMemo(() => {
    const domesticDetailsArray = [
      {
        label: "Name",
        value: accountName,
        eventName: "dashboard_account_details_account_name_copied",
      },
      {
        label: "Account number",
        value: accountNumber,
        eventName: "dashboard_account_details_account_number_copied",
      },
    ];
    if (bsb) {
      domesticDetailsArray.push({
        label: "Sort code",
        value: bsb,
        eventName: "dashboard_account_details_bsb_number_copied",
      });
    }
    return domesticDetailsArray;
  }, [accountName, accountNumber, bsb]);

  const internationalDetails = useMemo(
    () => [
      {
        label: "Bank name",
        value: bankName,
        eventName: "dashboard_account_details_bank_name_copied",
      },
      {
        label: "SWIFT/BIC",
        value: swiftCode,
        eventName: "dashboard_account_details_swift_copied",
      },
    ],
    [bankName, swiftCode],
  );

  return (
    <>
      <div className="tw-pl-2 sm:tw-pl-3">
        <div className="tw-flex tw-text-xs tw-pb-1">Domestic</div>
        {domesticDetails.map(({ label, eventName, value }) => (
          <AccountDetailsCopyLineItem
            key={label}
            label={label}
            eventName={eventName}
            value={value}
          />
        ))}

        <div className="tw-flex tw-text-xs tw-pt-4 tw-pb-1">International</div>
        {internationalDetails.map(({ label, eventName, value }) => (
          <AccountDetailsCopyLineItem
            key={label}
            label={label}
            eventName={eventName}
            value={value}
          />
        ))}
      </div>
      <div className="tw-mt-2">
        <p className="tw-prose prose-a:tw-text-blue-600 prose-a:tw-font-semibold !tw-text-xs tw-px-2">
          {`${I18n.t(
            "home.index.account_details.prompt_paragraph",
          )} Learn more about your `}
          <a
            href={I18n.t("hnry_account_link", ctx)}
            target="_blank"
            rel="noreferrer"
          >
            Hnry account
          </a>
          {" & "}
          <a
            href={I18n.t("international_payments_link", ctx)}
            target="_blank"
            rel="noreferrer"
          >
            International payments
          </a>
          .
        </p>
        <AccountDetailsShareModule currentUser={currentUser} />
      </div>
    </>
  );
};

export default AccountDetailsModule;
