import React from "react";
import classNames from "classnames";
import Icon from "../../_atoms/icons/icon/Icon";
import HnryCardImg from "../../../../assets/images/hnry_card/hnry_card.svg";
import I18n from "../../../utilities/translations";

interface iJoinWaitlist {
  /*
  * Url goes to Hnry card marketing page
  */
  cardUrl: string;
  /*
  * True if the join waitlist application is enabled and user hasn't joined waitlist.
  */
  showJoinWaitlist: boolean;
  /*
  * True if the user already joined the waitlist
  */
  hasJoinedCardWaitlist: boolean;
}

const cardIdx = { scope: "home.index.hnry_card.coming_soon" };

const JoinWaitlist = ({
  cardUrl,
  showJoinWaitlist,
  hasJoinedCardWaitlist,
}: iJoinWaitlist) => (
  <>
    {/* header */}
    <div className="hui-card-heading">
      <h2 className="max-xs:tw-text-base hui-card-heading__title">
        {I18n.t("title", cardIdx)}
      </h2>
      <div className="hui-card-heading__actions !tw-hidden sm:!tw-block ">
        <a
          className="hnry-button hnry-button--link hnry-button--icon-end"
          href={I18n.t("join_waitlist_learn_more", cardIdx)}
          target="_blank"
          rel="noreferrer"
          data-track-click={JSON.stringify({
            eventName: "dashboard_card_goto_hnry_card_marketing",
          })}
        >
          <Icon classes="-tw-ml-2" type="ArrowUpRightIcon" />
          <span className="tw-sr-only">{I18n.t("goto_link_text", cardIdx)}</span>
        </a>
      </div>
    </div>

    {/* content */}
    <div className={classNames("tw-flex tw-flex-col tw-grow tw-items-center tw-justify-evenly tw-mt-5 tw-mx-3 sm:tw-mx-6", { "tw-mb-0": hasJoinedCardWaitlist, "tw-mb-6": !hasJoinedCardWaitlist })}>
      <img
        src={HnryCardImg}
        className={classNames("tw-w-full tw-rounded-xl tw-mb-4", { "tw-bg-white tw-opacity-20": hasJoinedCardWaitlist })}
        alt="Hnry debit card"
      />
      <h3 className="tw-text-sm xs:tw-text-base tw-text-brand-700 tw-font-semibold tw-mb-3 tw-whitespace-nowrap">{I18n.t("heading", cardIdx)}</h3>
      {(!showJoinWaitlist && !hasJoinedCardWaitlist) && <p className="tw-text-sm tw-text-gray-500">{I18n.t("slogan", cardIdx)}</p>}
      {showJoinWaitlist && (
        <a
          className="hnry-button hnry-button--primary"
          href={cardUrl}
          data-track-click={JSON.stringify({
            eventName: "card_home_waitlist_banner_learn_more_clicked",
          })}
        >
          Join waitlist
        </a>
      )}
    </div>
    {hasJoinedCardWaitlist && (
      <div className="tw-bg-brand-50 tw-rounded-md tw-px-4 tw-py-3 -tw-mx-4 -tw-mb-4 tw-text-xs tw-text-gray-700 tw-text-center">
        {I18n.t("joined_waitlist", cardIdx)}
      </div>
    )}
  </>
);

export default JoinWaitlist;
