const SEQUENCE_LABEL_COMPONENTS = {
  alpha: {
    start: "A",
    end: "Z",
    connector: "–",
  },
  date: {
    start: "Old",
    end: "New",
    connector: "to",
  },
  numeric: {
    start: "Low",
    end: "High",
    connector: "to",
  },
};

export default SEQUENCE_LABEL_COMPONENTS;
