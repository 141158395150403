import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../tooltip";

const Table = ({
  children, title, code, show, warningTooltip,
}) => {
  if (!show) return null;

  return (
    <table className="table custom-card-filing__table table-hover">
      <thead>
        <tr className="row__header ">
          <th colSpan="3">
            {title}
            {warningTooltip && <Tooltip text={warningTooltip} icon="warning" otherClasses="orange-text ml-2" />}
          </th>
          <th style={{ width: "30px" }}>{code}</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};

Table.defaultProps = {
  show: true,
  code: "",
  children: null,
  warningTooltip: "",
};

Table.propTypes = {
  show: PropTypes.bool,
  code: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  warningTooltip: PropTypes.string,
};

export default Table;
