import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../tooltip";
import CopyButton from "../../../CopyButton";

const Row = ({
  colWidth1,
  colWidth2,
  fieldName,
  value,
  copyLabel,
  code,
  warningTooltip,
  copyFieldName,
  className,
}) => {
  const copyId = copyLabel ? `copy-${copyLabel}` : "";

  return (
    <tr className={className}>
      <td className="font-weight-bold" style={{ width: colWidth1 }}>
        <span data-copy-trigger={fieldName}>{fieldName}&nbsp;</span>
        {copyFieldName && <CopyButton label={`Copy ${fieldName} field`} copyId={fieldName} />}
        {warningTooltip && <Tooltip text={warningTooltip} icon="warning" otherClasses="orange-text ml-2" />}
      </td>
      <td style={{ width: colWidth2 }} data-copy-exclude="$" data-copy-trigger={copyId}>
        {value}
      </td>
      <td>
        {copyLabel && <CopyButton label={`Copy ${fieldName} to clipboard`} copyId={copyId} />}
      </td>
      <td style={{ width: "30px" }} className="font-weight-bold">{code}</td>
    </tr>
  );
};

Row.defaultProps = {
  colWidth1: "520px",
  colWidth2: "10%",
  copyLabel: "",
  code: "",
  warningTooltip: "",
  copyFieldName: false,
  className: "",
  value: null,
};

Row.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  colWidth1: PropTypes.string,
  colWidth2: PropTypes.string,
  copyLabel: PropTypes.string,
  code: PropTypes.string,
  warningTooltip: PropTypes.string,
  copyFieldName: PropTypes.bool,
  className: PropTypes.string,
};

export default Row;
