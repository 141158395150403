import React, { useReducer, useEffect } from "react";
import { Transition } from "@headlessui/react";
import ComboBox from "../../_molecules/combobox/Combobox";
import Input from "../../_atoms/input/Input";
import Icon from "../../_atoms/icons/icon/Icon";
import AuAddressFields from "../../_molecules/address_forms/AuAddressFields";
import NzAddressFields from "../../_molecules/address_forms/NzAddressFields";
import UkAddressFields from "../../_molecules/address_forms/UkAddressFields";

import {
  Address,
  AddressAutocompleteDispatches,
  AddressAutocompleteProps,
  AddressAutocompleteState,
} from "./types";
import {
  getAddressAutoComplete,
  getAddressDetails,
} from "../../../API/address.api";

const MIN_QUERY_LENGTH = 1;
const DEFAULT_PRESELECT_ID = "preselect-without-id";
const DEBOUNCE_INTERVAL = 400;

/**
 * Grabs a list of suggestions provided by Google Places from the backend
 * @param {string} query - The user input to filter by
 * @returns {Promise} An Promise that should resolve to an array of Partial Address objects
 */
const fetchAddressSuggestions = async (query: string) => {
  const suggestions = [];

  const data = await getAddressAutoComplete(query);

  const { addresses }: { addresses: Partial<Address>[] } = data.list;
  if (addresses) suggestions.push(...addresses);

  return suggestions;
};

/**
 * Grabs a list of suggestions provided by Google Places from the backend
 * @param {string} googlePlaceId - A googlePlaceId to fetch details for
 * @returns {Promise<Address>} A Promise that should resolve to Address object
 */
const fetchAddressDetails = async (googlePlaceId: string) => {
  const data = await getAddressDetails(googlePlaceId);

  const { details }: { details: Partial<Address> } = data.list;

  return details;
};

const newAddress: Required<Address> = {
  addressLine1: "",
  city: "",
  country: "",
  formattedAddress: "",
  googlePlaceId: "",
  postTown: "",
  postcode: "",
  state: "",
  streetAddress: "",
  streetNumber: "",
  suburb: "",
};

const defaultState: AddressAutocompleteState = {
  fields: { ...newAddress },
  isLoading: false,
  moreDetails: false,
  query: "",
  stateRequired: false,
  suggestions: [],
};

const stateReducer = (
  state: AddressAutocompleteState,
  action: AddressAutocompleteDispatches,
) => {
  switch (action.type) {
    case "ENTER_MANUAL_ADDRESS":
      return {
        ...state,
        fields: { ...newAddress },
        moreDetails: true,
      };

    case "IS_LOADING":
      return {
        ...state,
        isLoading: true,
        suggestions: [
          {
            key: "",
            value: "Loading...",
          },
        ],
      };

    case "UPDATE_ADDRESS_FIELDS":
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
        stateRequired: action.payload.country
          ? action.payload.country.trim().toUpperCase() === "AUSTRALIA"
          : state.stateRequired,
      };

    case "UPDATE_QUERY":
      return {
        ...state,
        query: action.payload,
      };

    case "UPDATE_MORE_DETAILS":
      return {
        ...state,
        moreDetails: action.payload,
      };

    case "UPDATE_SELECTED_SUGGESTION":
      return action.payload.googlePlaceId === "Other"
        ? {
            ...state,
            moreDetails: true,
          }
        : {
            ...state,
            fields: { ...newAddress, ...action.payload },
          };

    case "UPDATE_SUGGESTIONS":
      return {
        ...state,
        suggestions: action.payload,
        isLoading: false,
      };

    case "CLEAR_SELECTION":
      return {
        ...state,
        fields: { ...newAddress },
        query: "",
      };

    default:
      return state;
  }
};

const removeEmptyKeys = (obj: Partial<Address>) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      (entry) => entry[1] !== null && entry[1] !== undefined,
    ),
  );

const AddressAutocomplete = ({
  addressRequired,
  formNames,
  formValues,
  jurisdictionCode,
  showMoreDetails,
  displayLabel = "Address (cannot be a PO Box)",
}: AddressAutocompleteProps) => {
  const [state, stateDispatch] = useReducer(stateReducer, {
    ...defaultState,
    fields: {
      ...defaultState.fields,
      ...removeEmptyKeys(formValues),
      googlePlaceId: formValues.googlePlaceId || DEFAULT_PRESELECT_ID,
    },
    moreDetails: showMoreDetails,
    suggestions: [
      {
        key: formValues.googlePlaceId || DEFAULT_PRESELECT_ID,
        value: formValues.formattedAddress,
      },
    ],
  });

  const { fields, isLoading, moreDetails, query, stateRequired, suggestions } =
    state;

  /*
   * Effects to update ReducerState if props change
   */
  useEffect(() => {
    const valuesToUpdate = formValues;
    /*
     * If no GooglePlaceID supplied we need to use our DEFAULT_PRESELECT_ID to
     * ensure the input field renders the supplied formattedAddress
     */
    valuesToUpdate.googlePlaceId =
      valuesToUpdate.googlePlaceId || DEFAULT_PRESELECT_ID;
    stateDispatch({
      type: "UPDATE_ADDRESS_FIELDS",
      payload: valuesToUpdate,
    });
  }, [formValues]);

  useEffect(() => {
    stateDispatch({
      type: "UPDATE_MORE_DETAILS",
      payload: !!showMoreDetails,
    });
  }, [showMoreDetails]);

  /*
   * Dispatchers
   */
  const setQuery = (searchQuery: string) => {
    stateDispatch({
      type: "UPDATE_QUERY",
      payload: searchQuery,
    });
  };

  const onManualInput = (input, component) => {
    stateDispatch({
      type: "UPDATE_ADDRESS_FIELDS",
      payload: {
        [component]: input,
        googlePlaceId: "",
      },
    });
  };

  const setSelectedSuggestion = async (suggestionId: string | number) => {
    const suggestionIdString: string = suggestionId?.toString() || "";

    if (suggestionIdString === "" || suggestionIdString === null) {
      stateDispatch({
        type: "CLEAR_SELECTION",
      });
      return;
    }

    if (suggestionIdString === "Other") {
      stateDispatch({
        type: "ENTER_MANUAL_ADDRESS",
      });
      return;
    }

    try {
      const response = await fetchAddressDetails(suggestionIdString);

      stateDispatch({
        type: "UPDATE_SELECTED_SUGGESTION",
        payload: {
          ...response,
          googlePlaceId: suggestionIdString,
        },
      });
    } catch (e) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning(e);
      }
    }
  };

  const setMoreDetails = (evenMoreDetails: boolean) => {
    stateDispatch({
      type: "UPDATE_MORE_DETAILS",
      payload: evenMoreDetails,
    });
  };

  /*
   * Effect that updates suggestions based on query, in a debounced fashion
   */
  useEffect(() => {
    if (query.length < MIN_QUERY_LENGTH) return;
    const timeoutId = setTimeout(async () => {
      stateDispatch({
        type: "IS_LOADING",
        payload: true,
      });
      try {
        const addressSuggestions = await fetchAddressSuggestions(query);

        const suggestionOptions = addressSuggestions.map((suggestion) => ({
          key: suggestion.googlePlaceId,
          value: suggestion.formattedAddress,
        }));

        stateDispatch({
          type: "UPDATE_SUGGESTIONS",
          payload: suggestionOptions,
        });
      } catch (e) {
        if (typeof Rollbar !== "undefined") {
          Rollbar.warning(e);
        }
      }
    }, DEBOUNCE_INTERVAL);
    return () => clearTimeout(timeoutId);
  }, [query]);

  return (
    <>
      <div className="tw-flex tw-gap-x-2 tw-items-end">
        <div className="tw-grow">
          <ComboBox
            entries={suggestions}
            fallbackOption={
              query.length > 3 &&
              !isLoading && {
                key: "Other",
                value: "I can't find my address 😥",
              }
            }
            label={displayLabel}
            name="address-autocomplete"
            nullable={true}
            placeholder="Start typing..."
            required={!moreDetails && addressRequired}
            filterFunction={(_, entries) => entries.slice()}
            query={query}
            setQuery={setQuery}
            selectedValue={fields.googlePlaceId}
            setSelectedValue={setSelectedSuggestion}
          />
        </div>
        <button
          type="button"
          className="tw-h-16"
          title="Manually type my address"
          onClick={(e) => {
            e.preventDefault();
            setMoreDetails(!moreDetails);
          }}
        >
          <span className="tw-sr-only">Manually type my address</span>
          <Icon hoverOn={true} classes="tw-mt-5" />
        </button>
      </div>

      <Transition
        show={moreDetails}
        enter="tw-transition-all tw-ease tw-duration-300"
        enterFrom="tw-opacity-0 -tw-translate-y-16 tw-max-h-0"
        enterTo="tw-opacity-100 tw-max-h-96"
        leave="tw-transition-all tw-ease tw-duration-300"
        leaveFrom="tw-opacity-100 tw-max-h-96"
        leaveTo="tw-opacity-0 -tw-translate-y-16 tw-max-h-0"
        unmount={false}
      >
        {() => {
          switch (jurisdictionCode) {
            case "au":
              return (
                <AuAddressFields
                  addressRequired={moreDetails && addressRequired}
                  fields={fields}
                  formNames={formNames}
                  onManualInput={onManualInput}
                  stateRequired={stateRequired}
                />
              );
            case "nz":
              return (
                <NzAddressFields
                  addressRequired={moreDetails && addressRequired}
                  fields={fields}
                  formNames={formNames}
                  onManualInput={onManualInput}
                />
              );
            case "uk":
              return (
                <UkAddressFields
                  addressRequired={moreDetails && addressRequired}
                  fields={fields}
                  formNames={formNames}
                  onManualInput={onManualInput}
                />
              );
            default:
              return null;
          }
        }}
      </Transition>
      <Input
        name={formNames.googlePlaceId}
        value={
          fields.googlePlaceId === DEFAULT_PRESELECT_ID
            ? ""
            : fields.googlePlaceId
        }
        type="hidden"
      />
    </>
  );
};

export default AddressAutocomplete;
